// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableAreas(unit) {
	const controller = '/pmarea/disableAreas';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		unit,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableAreas(unit) {
	const controller = '/pmarea/enableAreas';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		unit,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
				console.log(error);
			}
		}
	);
	return data;
}
