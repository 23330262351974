<template>
	<div>
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import AreaActions from './PmAreaActions';

//import constants from '@/utils/constants';
import { getAreas, isSuperAdmin, search } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmareagrid',
	data() {
		return {
			model: 'pmarea',
			actions: AreaActions.actions,
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			masterDetailColumnVisibles: {
				name: true,
				acronym: true,
				areatype: true,
				description: true,
				organizationname: true,
				disabled: true
			},
			externalFilter: null
		};
	},
	computed: {},
	mounted() {
		// eslint-disable-next-line no-unused-vars
		this.setAreaDefinitions();
		if (!isSuperAdmin(this.session.profiles[0])) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
	},

	beforeDestroy() {
		console.log('BEFORE DESTROY');
		//this.doAsync();
	},
	methods: {
		async doAsync() {
			var start = Date.now(),
				time;
			console.log(0);
			time = await this.doSomethingAsync(100);
			console.log(time - start);
		},

		doSomethingAsync(time) {
			//return this.timeoutPromise(1000);
			return new Promise(function (resolve) {
				setTimeout(function () {
					getAreas(this.session.language, this.userProperties);
					resolve(Date.now());
				}, time);
			});
		},

		timeoutPromise(time) {
			return new Promise(function (resolve) {
				setTimeout(function () {
					var session = this.$store.getters.getSession;
					getAreas(session.language, this.session);
					resolve(Date.now());
				}, time);
			});
		},
		async setAreaDefinitions() {
			let body = {
				model: 'pmareadefinition',
				rows: 1000,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
				}
			};
			const { data } = await search(body);
			console.log('After get data - Area Def', data.data);
			this.$store.commit('setAreaDefinitions', data.data);
		}
	}
};
</script>

<style lang="postcss" scoped></style>
